import * as moment from 'moment'
import ct from 'countries-and-timezones'
export const interval_utils = {
    data: () => ({
        selected_interval: {
            start_date: null,
            end_date: null,
            start_time: null,
            end_time: null,
            interval_type: 'none',
            interval_at: null,
            timezone: 'utc'
        }
    }),
    computed: {
        timezones() {
            return Object.values(ct.getAllTimezones())
                .filter((i) => {
                    return (
                        (i.name.includes('/') && !i.name.includes('Etc/')) ||
                        i.name === 'UTC'
                    )
                })
                .map((i) => {
                    i.fullname = `${i.name} (${i.utcOffsetStr})`
                    return i
                })
                .sort((a, b) => (a.name > b.name ? 1 : -1))
        },
        interval_type_at() {
            if (!this.interval_types) return []
            let index = this.interval_types.findIndex(
                (i) => i.value === this.selected_interval.interval_type
            )
            if (~index) return this.interval_types[index].at
            return []
        },
        every_hour_at() {
            const hours = []
            for (let hour = 0; hour < 24; hour++) {
                hours.push(moment({ hour }).format('h:mm A'))
            }
            return hours
        },
        every_week_at() {
            return this.weekdays
        },
        every_month_at() {
            const nth = []
            const days = 31
            for (let day = 1; day < days; day++) {
                nth.push({
                    value: `${day}`,
                    text: `${this.getOrdinalNum(day)} Of The Month`
                })
            }
            nth.push({ value: 'last', text: `Last Day Of The Month` })
            return nth
        },
        interval_types() {
            return [
                { text: 'One time', value: 'none', at: [] },
                { text: 'Every Hour', value: 'every_hour', at: [] },
                { text: 'Every Day At', value: 'every_day_at', at: this.every_hour_at },
                {
                    text: 'Every Week On',
                    value: 'every_week_at',
                    at: this.every_week_at
                },
                {
                    text: 'Every Month On',
                    value: 'every_month_at',
                    at: this.every_month_at
                }
            ]
        }
    },
    methods: {
        getOrdinalNum(number) {
            return (
                number +
                (number > 0 ?
                    ['th', 'st', 'nd', 'rd'][
                        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
                    ] :
                    '')
            )
        }
    }
}