import { api_to } from './api'
import { fetch_services } from '@/services/fetch/fetch_services'
import { mapGetters, mapMutations } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import { interval_utils } from '@/global_utils/interval_utils'
//Components
import TableHeader from '@/common/TableHeader.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'
import EmailDialog from './components/EmailDialog/EmailDialog.vue'
import InvoiceDialog from './components/InvoiceDialog/InvoiceDialog.vue'
import ViewInvoice from './components/ViewInvoice/ViewInvoice.vue'
import Actions from '@/common/VueTable/ActionDropdown.vue'
import VueTable from '@/common/VueTable/VueTable.vue'
import StepperInvoiceDialog from './components/StepperDialog/StepperInvoiceDialog.vue'

export default {
    name: 'Invoice',
    mixins: [fetch_services, interval_utils],

    components: {
        TableHeader,
        InvoiceDialog,
        StepperInvoiceDialog,
        VueTable,
        EmailDialog,
        DeleteDialog,
        ViewInvoice,
        Actions
    },

    data: () => ({
        componentKey: 0,
        selected: [],
        view_invoice_dialog: false,
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Invoice', disabled: true, route: null }
        ],
        headers: [
            { text: 'Due Date', sortable: true, value: 'due_date' },
            { text: 'Invoice #', sortable: true, value: 'id_normalize' },
            { text: 'Title', sortable: true, value: 'title' },
            { text: 'Billed From', sortable: false },
            { text: 'Billed To', sortable: false },
            { text: 'Type', sortable: true, value: 'type' },
            { text: 'Amount', sortable: true, value: 'total' },
            { text: 'Status', sortable: true, value: 'status' },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                width: '40px',
                align: 'center'
            }
        ],
        subheaders: [
            { text: 'Due Date', sortable: true, value: 'due_date' },
            { text: 'Invoice #', sortable: true, value: 'id_normalize' },
            { text: 'Title', sortable: true, value: 'title' },
            { text: 'Billed From', sortable: false },
            { text: 'Billed To', sortable: false },
            { text: 'Amount', sortable: true, value: 'total' },
            { text: 'Status', sortable: true, value: 'status' },
            {
                text: 'Action',
                sortable: false,
                value: 'action',
                width: '40px',
                align: 'center'
            }
        ],
        view_item: null,
        table_config: {
            add_message: 'New invoice added successfully!',
            update_message: 'Invoice updated successfully!',
            delete_message: 'Invoice deleted successfully!',
            refresh_table_message: 'Table refreshed'
        },
        filter: {
            status: 'all'
        },
        btn_reminding: false
    }),

    created() {
        this.getInvoices()
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    beforeDestroy() {
        this.$store.commit('invoice/clear_invoice')
    },

    computed: {
        ...mapGetters(['user'])
    },

    methods: {
        can_pay(item) {
            return item.billable_to_id === this.user.id
        },
        navigatePayment(item) {
            this.$router.push({
                name: 'payment-to-invoice',
                params: { id: item.uuid }
            })
        },
        open_create_dialog() {
            this.$router.push({ name: 'invoice-maker' })
            this.$store.commit('invoice/set_is_editting', false)
        },

        open_edit_dialog(item) {
            this.$router.push({ name: 'invoice-maker', params: { id: item.id } })
            this.$store.commit('invoice/set_is_editting', true)
        },
        open_view_dialog(data) {
            this.view_item = data
                // this.view_invoice_dialog = true;
            this.popupCenter({
                url: data.view_url,
                title: data.title,
                w: 900,
                h: 550
            })
        },

        delete_invoice() {
            this.loading = true
            api_to
                .delete_invoice(this.delete_item_id)
                .then(() => {
                    const index = this.items.findIndex(
                        (item) => item.id === this.delete_item_id
                    )
                    if (~index) this.items.splice(index, 1)
                })
                .finally(() => {
                    this.loading = false
                    this.delete_dialog = false
                })
        },

        invoice_updated(invoice) {
            const index = this.items.findIndex((item) => item.id === invoice.id)
            if (~index) {
                this.items.splice(index, 1, invoice)
            }
        },
        showInvoice(status) {
            this.filter.status = status
            this.getInvoices()
        },
        getInvoices() {
            this.loadItems(
                `api/invoice?status=${this.filter.status}&parent_only=true`,
                true,
                null,
                null
            )
        },
        remind_invoice(item) {
            item.extra.btnloading = true
            api_to
                .bulk_remind({ invoice_ids: [item.id] })
                .then(({ data }) => {
                    this.appSnackbar('Invoice reminder sent!')
                    this.$event.$emit('clear_selected')
                })
                .finally(() => {
                    item.extra.btnloading = false
                })
        },
        remind_invoices() {
            if (this.selected.length <= 0) {
                this.appSnackbar('Please select invoice(s)', 'error')
                return
            }
            this.btn_reminding = true
            let payload = {
                invoice_ids: this.selected.map((value, index) => {
                    return value.id
                })
            }
            api_to
                .bulk_remind(payload)
                .then(({ data }) => {
                    this.appSnackbar('Invoice reminders sent!')
                    this.$event.$emit('clear_selected')
                })
                .finally(() => {
                    this.btn_reminding = false
                })
        },
        getDynamiceDate(invoice, column, default_col) {
            if (invoice.type === 'weekly') {
                return `Every ${this.weekdays()[Number(invoice[column])]}`
            } else if (invoice.type === 'monthly') {
                if (Number(invoice[column]) === 31) {
                    return 'Every last day of month'
                }
                return `Every ${this.getOrdinalNum(Number(invoice[column]))} of month`
            } else {
                return invoice[default_col]
            }
        },
        toggleRecurringStatus(invoice) {
            api_to
                .toggle_recurring_status({ invoice: invoice.id })
                .then(({ data }) => {
                    let index = this.items.findIndex((i) => i.id === invoice.id)
                    if (~index) {
                        this.items[index].status =
                            invoice.status === 'active' ? 'inactive' : 'active'
                    }
                    this.appSnackbar(
                        invoice.status === 'active' ?
                        'Invoice recurring deactivated' :
                        'Invoice recurring activated'
                    )
                })
        }
    }
}