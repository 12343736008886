import request from '@/services/axios_instance'

export const api_to = {
    get_invoices(page) {
        return request.get(`api/invoice?page=${page}`)//&sort=id|desc
    },

    delete_invoice(id) {
        return request.delete(`api/invoice/${id}`)
    },

    create_invoice(payload) {
        return request.post('api/invoice', payload)
    },

    update_invoice(payload, id) {
        return request.post(`api/invoice/${id}`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },

    get_all_projects() {
        return request.get('api/projects?for=invoice&all=true')
    },

    get_projects_tasks(id) {
        return request.get(`api/projects/${id}/tasks-for-invoice?limit=20`)
    },

    bulk_remind(payload) {
        return request.post(`api/invoice/bulk-remind`, payload)
    },

    toggle_recurring_status(payload) {
        return request.post(`api/invoice/toggle-recurring`, payload)
    }
}