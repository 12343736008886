<template>
  <v-row no-gutters>
    <v-col md="5" sm="6">Company Logo</v-col>
    <v-col md="7" sm="6">
      <div
        class="add__logo_box"
        v-if="image_preview && image_preview !== `null`"
      >
        <v-img
          :lazy-src="settings.loader"
          :src="image_preview"
          class="image-preview"
        />
        <v-btn outlined class="button" v-show="image_preview">Remove</v-btn>
      </div>
      <div class="add__logo_box" v-else @click="$refs.hidden_input.click()">
        <span class="text">+ Add Your Logo</span>
        <input
          type="file"
          accept="image/*"
          ref="hidden_input"
          @change="file_selected"
          class="hidden-input"
        />
      </div>
    </v-col>
    <v-col md="5" sm="6" v-if="type === `monthly`" class="label">
      <p>
        Recurring Invoice?
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span>
            An recurring invoice is merely an invoice that is sent to the same
            recepient at regular monthly intervals, containing the same invoice
            details
          </span>
        </v-tooltip>
      </p>
    </v-col>
    <v-col md="7" sm="6" v-if="type === `monthly`" class="value">
      <v-radio-group
        hide-details
        :mandatory="true"
        row
        :input-value="is_recurring"
        v-model="is_recurring"
        class="mt-0 pt-0"
      >
        <v-radio label="Yes" value="1" color="primary" />
        <v-radio label="No" value="0" color="primary" />
      </v-radio-group>
    </v-col>
    <v-col md="5" sm="6" class="label">
      <p>Send email to recepient?</p>
    </v-col>
    <v-col md="7" sm="6" class="value">
      <v-radio-group
        :mandatory="true"
        row
        v-model="props.send_email"
        :input-value="props.send_email"
        class="mt-0 pt-0"
      >
        <v-radio label="Yes" value="yes" color="primary" />
        <v-radio label="No" value="no" color="primary" />
      </v-radio-group>
      <!-- <v-switch v-model.trim="props.send_email" :value="props.send_email"></v-switch> -->
    </v-col>
    <v-col md="5" sm="6" class="label">Invoice Template</v-col>
    <v-col md="7" sm="6" class="value">
      <v-select
        color="#657186"
        :items="templates"
        v-model="props.template"
        placeholder="Select template"
        item-text="name"
        item-value="id"
        solo
        flat
        outlined
        dense
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import request from '@/services/axios_instance'

export default {
  name: 'StepThree',
  data: () => ({
    templates: [],
    image_preview: null
  }),
  computed: {
    ...mapGetters('invoice', [
      'props',
      'dialog',
      'company_logo',
      'type',
      'parent'
    ]),
    is_recurring: {
      get() {
        return this.$store.getters['invoice/is_recurring']
      },
      set(val) {
        this.set_is_recurring(val)
      }
    }
  },
  watch: {
    company_logo(val) {
      if (!val) {
        this.image_preview = null
        return
      }
      if (typeof val === 'object') {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.image_preview = e.target.result
        }
        reader.readAsDataURL(val)
      } else {
        this.image_preview = val
      }
    }
  },
  mounted() {
    this.getTemplates()
  },
  methods: {
    ...mapMutations('invoice', ['set_is_recurring']),
    file_selected(event) {
      if (event.target.files && event.target.files[0]) {
        let formData = new FormData()
        formData.append('file', event.target.files[0])
        request
          .post(`api/file/image-upload`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(({ data }) => {
            this.$store.commit('invoice/set_company_logo', data.url)
          })
      }
    },
    getTemplates() {
      request.get(`api/template/invoices`).then(({ data }) => {
        this.templates = data.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/sass/variables';

.required {
  color: red;
}

.add__logo_box {
  position: relative;
  height: 150px;
  background-color: $bgLightBlue;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: $textDark;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: $fieldLabel;

    > .button {
      display: block;
    }
  }

  .text,
  .button {
    position: absolute;
  }

  .button {
    display: none;
  }

  .image-preview {
    position: absolute;
    height: auto;
    width: auto;
    max-height: 150px;
    max-width: 200px;
  }

  .hidden-input {
    visibility: hidden;
  }
}
</style>
<style scoped>
>>> .v-select__selection.v-select__selection--comma {
  color: #667381;
}

>>> .v-list-item__title {
  color: #667381;
  font-size: 16px;
}
</style>
