import { render, staticRenderFns } from "./EmailDialog.vue?vue&type=template&id=b4cfa6d8&scoped=true&"
import script from "./EmailDialog.js?vue&type=script&lang=js&"
export * from "./EmailDialog.js?vue&type=script&lang=js&"
import style0 from "./EmailDialog.scss?vue&type=style&index=0&id=b4cfa6d8&lang=scss&scoped=true&"
import style1 from "./EmailDialog.css?vue&type=style&index=1&id=b4cfa6d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4cfa6d8",
  null
  
)

export default component.exports